import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Biography from './pages/Biography/Biography';
import Contact from './pages/Contact/Contact';
import Home from './pages/Home/Home';
import Pricing from './pages/Pricing/Pricing';
import Projects from './pages/Projects/Projects';
import './_app.scss';

const App = () => {
  // CHANGE WEBSITE LANGUAGE
  const [srb, setSrb] = useState(true);
  const [langcode, setSelectedValue] = useState('sr');

  useEffect(() => {
    const script = document.createElement('script');
    const token = 'fe663a72b27bdc613873fbbb512f6f67';
    script.src = `https://www.teslamandic.org/${langcode}/hoqmee-partners/load/script?l=${langcode}&t=${token}`;
    script.async = true;
    script.setAttribute('data-langcode', langcode);
    script.setAttribute('data-token', token);
    script.id = 'hoqmee-partners';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [langcode]);

  const changeToSrb = () => {
    setSrb(true);
    setSelectedValue('sr');
  };

  const changeToEng = () => {
    setSrb(false);
    setSelectedValue('en');
  };

  return (
    <Router>
      <div className='container'>
        <div className='overlay'>
          <Routes>
            <Route
              path='*'
              element={
                <>
                  <Header
                    srb={srb}
                    changeToSrb={changeToSrb}
                    changeToEng={changeToEng}
                  />
                  <Routes>
                    <Route path='/' element={<Home srb={srb} />} />
                    <Route
                      path='/biography'
                      element={<Biography srb={srb} />}
                    />
                    <Route
                      path='/projects/:id'
                      element={<Projects srb={srb} />}
                    />
                    <Route path='/pricing' element={<Pricing srb={srb} />} />
                    <Route path='/contact' element={<Contact srb={srb} />} />
                  </Routes>
                  <Footer srb={srb} />
                </>
              }
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
