import React from 'react';
import './_projects.scss';
import { useParams } from 'react-router-dom';
import ResidentialBuildings from './pages/ResidentialBuildings';
import ProjectsHome from './pages/ProjectsHome';
import PublicBuildings from './pages/PublicBuildings';
import Interiors from './pages/Interiors';
import BusinessAndProductionFacilities from './pages/BusinessAndProductionFacilities';
import Competitions from './pages/Competitions';
import OrtodoxBuildings from './pages/OrtodoxBuildings';

const Projects = ({ srb }) => {
  const { id } = useParams();
  const renderPages = () => {
    switch (id) {
      case 'residential-buildings':
        return <ResidentialBuildings />;
      case 'ortodox-buildings':
        return <OrtodoxBuildings />;
      case 'public-buildings':
        return <PublicBuildings />;
      case 'interiors':
        return <Interiors />;
      case 'business-and-production-facilities':
        return <BusinessAndProductionFacilities />;
      case 'competitions':
        return <Competitions />;
      default:
        return <ProjectsHome srb={srb} />;
    }
  };
  return (
    <div className='background-container'>{renderPages()}</div>
  );
};

export default Projects;
