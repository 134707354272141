import React from 'react';
import { Link } from 'react-router-dom';
import './_biography.scss';
import LicenceImagesDiv from '../../components/LicenceImagesDiv/LicenceImagesDiv';

const Biography = ({ srb }) => {
  return (
    <div className='biography'>
      <div className='biography-left'>
        <div className='biography-image'>
          <img src='/images/duca-skupstina-small.png' alt='Ковачевић Душан' />
        </div>
        <p className='biography-name'>
          {srb ? 'Ковачевић Душан' : 'Kovačević Dušan'}
        </p>
        <div className='biography-licences'>
          <p className='licences-title'>{srb ? 'Лиценце:' : 'Licences:'}</p>
          <div className='licence'>
            <LicenceImagesDiv imgSrc='/images/licence/projektovanje.png' />
            <p className='licence-name'>
              {srb
                ? 'Одговорни пројектант архитектуре'
                : 'Responsible architectural designer'}
            </p>
          </div>
          <div className='licence'>
            <LicenceImagesDiv imgSrc='/images/licence/urbanizam.png' />
            <p className='licence-name'>
              {srb ? 'Одговорни урбаниста' : 'Responsible urban planner'}
            </p>
          </div>
          <div className='licence'>
            <LicenceImagesDiv imgSrc='/images/licence/efikasnost.png' />
            <p className='licence-name'>
              {srb
                ? 'Одговорни пројектант енергетске ефикасности'
                : 'Responsible designer of energy efficiency'}
            </p>
          </div>
          <div className='licence'>
            <LicenceImagesDiv imgSrc='/images/licence/diploma.png' />
            <p className='licence-name'>
              {srb
                ? 'Диплома о високом образовању'
                : 'Diploma in Higher Education'}
            </p>
          </div>
          <div className='licence'>
            <LicenceImagesDiv imgSrc='/images/licence/uverenje.jpg' />
            <p className='licence-name'>
              {srb ? 'Уверење 3DS MAX' : '3DS MAX certificate'}
            </p>
          </div>
        </div>
      </div>
      <div className='biography-right'>
        <div className='biography-text'>
          <p className='indent'>
            {srb
              ? 'Рођен у Чачку 3. јануара 1985. године где је завршио основну школу Милица Павловић и средњу чачанску Гимназију. Уписује Архитектонски факултет Универзитета у Београду 2004. године, на коме завршава основне и мастер студије 2009. године са просечном оценом 9,39 и стиче звање дипломираног инжењера архитектуре.'
              : "Born in Čačak on January 3, 1985, where he graduated from Milica Pavlović Elementary School and Čačak High School. He entered the Faculty of Architecture at the University of Belgrade in 2004, where he completed his undergraduate and master's studies in 2009 with an average grade of 9.39 and earned the title of graduate engineer in architecture."}
          </p>
          <br />
          <p className='indent'>
            {srb
              ? 'У периоду од 2009. до 2022. године ради у фирми Урбанпројект Чачак. За време рада у Урбанпројекту стиче лиценце одговорног пројектанта пројеката архитектуре 2013. године, одговорног пројектанта енергетске ефикасности 2016. године и одговорног урбанисте 2017. године.'
              : 'In the period from 2009 to 2022, he worked in the company Urbanprojekt Čačak. During his work at Urbanprojekt, he obtained the licenses of the responsible designer of architecture projects in 2013, the responsible designer of energy efficiency in 2016 and the responsible urban planner in 2017.'}
          </p>
          <br />
          <p className='indent'>
            {srb
              ? 'Маја 2022. године оснива фирму Архитектонски студио КОД са седиштем у Чачку. Основна делатност Архитектонског студиa КОД је израда идејних решења архитектонских објеката са 3Д приказом и анимацијом. Поред израде идејних решења могућа је и израда пројеката за грађевинску дозволу, пројеката за извођење, као и употребних дозвола. Аркитектонски студио КОД се бави и израдом пројеката ентеријера, урбанистичких пројеката, пројеката парцелација и препарцелација, озакоњењем објеката и пројектантским надзором извођења радова.'
              : 'In May 2022, he founded the firm Architectural studio KOD with headquarters in Čačak. The main activity of Architectural studio KOD is the creation of conceptual solutions of architectural objects with 3D rendering and animation. In addition to conceptual solutions, it is also possible to create projects for building permits, construction projects, and use permits. The architectural studio KOD also deals with the creation of interior projects, urban planning projects, parceling and re-parceling projects, legalization of buildings and design supervision of the execution of works.'}
          </p>
        </div>
        <p className='biography-contact'>
          {srb ? 'Придружите нам се и' : 'Join us and'}{' '}
          <Link className='biography-contact-link' to='/contact'>
            {srb ? 'контактирајте нас' : 'contact us'}
          </Link>
          .<br />
        </p>
        <p className='biography-contact-span'>
          {srb
            ? 'Ваш Архитектонски студио КОД.'
            : 'Yours Architectural studio KOD.'}
        </p>
      </div>
    </div>
  );
};

export default Biography;
