import React from 'react';
import './_home.scss';

const Home = ({ srb }) => {
  return (
    <main>
      <div className='main-img-logo'>
        <img src='/images/logo-big-color.png' alt='logo' />
      </div>
      <div className='main-text'>
        <h1>{srb ? 'Ковачевић Душан' : 'Kovačević Dušan'}</h1>
        <p>{srb ? 'предузетник - архитекта' : 'enterpreneur - architect'}</p>
      </div>
    </main>
  );
};

export default Home;
