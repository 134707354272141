import './_buildingsImages.scss';

const PublicBuildings = () => {
  return (
    <div className='buildings-images'>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/javni/1 - AMFITEATAR OVCAR BANJA.jpg'
          alt='javni'
        />
      </div>
      <div className='buildings-images-img'>
        <img src='/images/projects/javni/2 - VATROGASCI.jpg' alt='enterijer' />
      </div>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/javni/3 - SPORTSKI CENTAR CACAK.jpg'
          alt='javni'
        />
      </div>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/javni/4 - ZATVORENI BAZEN GORWI MILANOVAC.jpg'
          alt='javni'
        />
      </div>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/javni/5 - PALIJATIVNO ZBIRNJAVANJE GORNJI MILANOVAC.jpg'
          alt='javni'
        />
      </div>
      <div className='buildings-images-img'>
        <img src='/images/projects/javni/6 - PIJACA GM.jpg' alt='javni' />
      </div>
      <div className='buildings-images-img'>
        <img src='/images/projects/javni/7 - TRG CACAK.jpg' alt='javni' />
      </div>
      <div className='buildings-images-img'>
        <img src='/images/projects/javni/8 - DOM ZDRAVLJA.jpg' alt='javni' />
      </div>
    </div>
  );
};

export default PublicBuildings;
