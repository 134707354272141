import React, { useState } from 'react';
import './_licenceImagesDiv.scss';
import { FaTimes } from 'react-icons/fa';

const LicenceImagesDiv = ({ imgSrc }) => {
  const [licence, setLicence] = useState(false);

  const handleLicence = (e) => {
    setLicence((prevState) => !prevState);
  };

  return (
    <>
      <div onClick={handleLicence} className='licence-image'>
        <img src={imgSrc} alt='Лиценца' />
      </div>
      {licence ? (
        <div
          className={
            imgSrc === '/images/licence/uverenje.jpg'
              ? 'licence-modal uverenje'
              : 'licence-modal'
          }
        >
          <img src={imgSrc} alt='Лиценца' />
          <div className='close-modal-div'>
            <FaTimes
              onClick={handleLicence}
              className='close-modal'
              size={50}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      {licence && <div onClick={handleLicence} className='moval'></div>}
    </>
  );
};

export default LicenceImagesDiv;
