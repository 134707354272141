import './_buildingsImages.scss';

const ResidentialBuildings = () => {
  return (
    <div className='buildings-images'>
    <div className='buildings-images-img'>
      <img
        src='/images/projects/stambeni/1 - STAMBENI OBJEKTI.jpg'
        alt='stambeni'
      />
    </div>
    <div className='buildings-images-img'>
      <img
        src='/images/projects/stambeni/2 - ZOKI NIS.jpg'
        alt='stambeni'
      />
    </div>
    <div className='buildings-images-img'>
      <img
        src='/images/projects/stambeni/3 - VLADE DAN I NOC.jpg'
        alt='stambeni'
      />
    </div>
    <div className='buildings-images-img'>
      <img
        src='/images/projects/stambeni/4 - DVORISTE SONE.jpg'
        alt='stambeni'
      />
    </div>
    <div className='buildings-images-img'>
      <img
        src='/images/projects/stambeni/5 - PECA ALPINISTA.jpg'
        alt='stambeni'
      />
    </div>
    <div className='buildings-images-img'>
      <img
        src='/images/projects/stambeni/6 - STAMBENA ZGRADA BOSNJAK.jpg'
        alt='stambeni'
      />
    </div>
    <div className='buildings-images-img'>
      <img
        src='/images/projects/stambeni/7 - PORODICNA KUCA ATENICA.jpg'
        alt='stambeni'
      />
    </div>
    <div className='buildings-images-img'>
      <img
        src='/images/projects/stambeni/8 - DEJO KRALJICA KAFA.jpg'
        alt='stambeni'
      />
    </div>
    <div className='buildings-images-img'>
      <img
        src='/images/projects/stambeni/9 - VILDOR CRNA GORA.jpg'
        alt='stambeni'
      />
    </div>
    <div className='buildings-images-img'>
      <img
        src='/images/projects/stambeni/10 - STAMBENI OBJEKTI.jpg'
        alt='stambeni'
      />
    </div>
  </div>
  );
};

export default ResidentialBuildings;
