import React from 'react';
import './_pricing.scss';

const Pricing = ({ srb }) => {
  return (
    <div className='pricing'>
      <h2>
        {srb
          ? 'Израда идејних решења са 3Д приказом (за потребе издавања локацијских услова):'
          : 'Creation of conceptual solutions with 3D view (for the needs of location conditions):'}
      </h2>
      <p className='pricing-p-bottom-margin'>
        {srb ? 'објекти до 500 м' : 'buildings up to 500 m'}
        <sup>2</sup> - {srb ? '5 евра/м' : '5 euros/m'}
        <sup>2</sup> {srb ? 'бруто површине' : 'gross area'}
      </p>
      <p className='pricing-p-bottom-margin'>
        {srb ? 'објекти од 500 м' : 'buildings from 500 m'}
        <sup>2</sup> {srb ? 'до 2000 м' : 'to 2000 m'}
        <sup>2</sup> - {srb ? '4 евра/м' : '4 euros/m'}
        <sup>2</sup> {srb ? 'бруто површине' : 'gross area'}
      </p>
      <p>
        {srb ? 'објекти преко 2000м' : 'buildings over 2000 m'}
        <sup>2</sup> - {srb ? '3 евра/м' : '3 euros/m'}
        <sup>2</sup> {srb ? 'бруто површине' : 'gross area'}
      </p>
      <h2>
        {srb
          ? 'Израда пројеката за грађевинску дозволу и пројеката за извођење:'
          : 'Creation of projects for building permits and projects for execution:'}
      </h2>
      <p className='pricing-p-bottom-margin'>
        {srb
          ? 'цена се формира по договору, у зависности од класе и категорије објекта'
          : 'the price is formed by agreement, depending on the class and category of the object'}
      </p>
      <h2>{srb ? 'Пројекти ентеријера:' : 'Interior projects:'}</h2>
      <p className='pricing-p-bottom-margin'>
        {srb
          ? 'израда идејног решења са 3Д приказом - 15 евра/м'
          : 'creation of conceptual solution with 3D rendering - 15 euros/m'}
        <sup>2</sup> {srb ? 'нето површине' : 'of net area'}
      </p>
      <p className='pricing-p-bottom-margin'>
        {srb
          ? 'израда пројеката за извођење са свим потребним инсталацијама - 15 евра/м'
          : 'development of projects for implementation with all necessary installations - 15 euros/m'}
        <sup>2</sup> {srb ? 'нето површине' : 'of net area'}
      </p>
      <p>
        {srb
          ? 'минимална цена пројекта 500 евра'
          : 'minimum project price 500 euroes'}
      </p>
      <h2>
        {srb
          ? 'Пројектантски надзор над извођењем радова:'
          : 'Design supervision over work performance:'}
      </h2>
      <p>
        {srb
          ? 'цена се формира по договору'
          : 'the price is formed by agreement'}
      </p>
    </div>
  );
};

export default Pricing;
