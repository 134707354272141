import React from 'react';
import ProjectsItem from '../../../components/ProjectsItem/ProjectsItem';
import { Link } from 'react-router-dom';

const ProjectsHome = ({ srb }) => {
  return (
    <div>
      <div className='projects'>
        <Link to={'/projects/residential-buildings'}>
          <ProjectsItem
            imgSrc='/images/projects/stambeni.jpg'
            title={srb ? 'Стамбени објекти' : 'Residential buildings'}
          />
        </Link>
        <Link to={'/projects/ortodox-buildings'}>
          <ProjectsItem
            imgSrc='/images/projects/pravoslavni.jpg'
            title={srb ? 'Православни објекти' : 'Ortodox buildings'}
          />
        </Link>
        <Link to='/projects/public-buildings'>
          <ProjectsItem
            imgSrc='/images/projects/javni.jpg'
            title={srb ? 'Јавни објекти' : 'Public buildings'}
          />
        </Link>
        <Link to='/projects/interiors'>
          <ProjectsItem
            imgSrc='/images/projects/enterijeri.jpg'
            title={srb ? 'Ентеријери' : 'Interiors'}
          />
        </Link>
        <Link to='/projects/business-and-production-facilities'>
          <ProjectsItem
            imgSrc='/images/projects/poslovni.jpg'
            title={
              srb
                ? 'Пословни и производни објекти'
                : 'Business and production facilities'
            }
          />
        </Link>
        <Link to='/projects/competitions'>
          <ProjectsItem
            imgSrc='/images/projects/konkursi.jpg'
            title={srb ? 'Конкурси' : 'Competitions'}
          />
        </Link>
      </div>
    </div>
  );
};

export default ProjectsHome;
