import React from 'react';
import './_buildingsImages.scss';

const Interiors = () => {
  return (
    <div className='buildings-images'>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/enterijeri/1 - SALA SAFT.jpg'
          alt='enterijer'
        />
      </div>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/enterijeri/2 - ZUBARSKA ORDINACIJA.jpg'
          alt='enterijer'
        />
      </div>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/enterijeri/3 - FINGER BAR.jpg'
          alt='enterijer'
        />
      </div>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/enterijeri/4 - OPTICKA RADNJA.jpg'
          alt='enterijer'
        />
      </div>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/enterijeri/5 - JAHORINA.jpg'
          alt='enterijer'
        />
      </div>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/enterijeri/6 - SALA ZA VESELJA DAN I NOC.jpg'
          alt='enterijer'
        />
      </div>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/enterijeri/7 - ENTERIJER SUBOTIC.jpg'
          alt='enterijer'
        />
      </div>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/enterijeri/8 - GARSONJERA KRALJICA.jpg'
          alt='enterijer'
        />
      </div>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/enterijeri/9 - MONA ZLATIBOR.jpg'
          alt='enterijer'
        />
      </div>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/enterijeri/10 - OKI MERMER.jpg'
          alt='enterijer'
        />
      </div>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/enterijeri/11 - NADA BEOGRAD.jpg'
          alt='enterijer'
        />
      </div>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/enterijeri/12 - STAN SUTJESKA.jpg'
          alt='enterijer'
        />
      </div>
    </div>
  );
};

export default Interiors;
