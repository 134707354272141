import React from 'react';
import './_footer.scss';

const Footer = ({ srb }) => {
  const year = new Date().getFullYear();

  return (
    <footer>
      <div style={{ maxWidth: '300px' }} id='hoqmee-partner-render'></div>
      <div className='footer-text'>
        <div className='copyright-text'>
          {srb ? (
            <div className='copyright'>
              <p>&copy; Ауторска права {year}</p>
              <p>Архитектонски студио КОД.</p>
              <p>Сва права задржана.</p>
            </div>
          ) : (
            <div className='copyright'>
              <p>&copy; Copyright {year}</p>
              <p>Architectural studio KOD.</p>
              <p> All Rights Reserved.</p>
            </div>
          )}
        </div>
        <div className='designer'>
          {srb ? (
            <p>Сајт израдио: Душан Живановић</p>
          ) : (
            <p>Site designed by: Dušan Živanović</p>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
