import React from 'react';
import './_contact.scss';
import {
  FaMapMarkerAlt,
  FaPhone,
  FaMobileAlt,
  FaEnvelope,
  FaInstagram,
  FaYoutube,
} from 'react-icons/fa';

const Contact = ({ srb }) => {
  return (
    <div className='contact'>
      <div className='contact-text'>
        <h2 className='contact-header'>
          {srb ? 'Адреса и контакти:' : 'Address and contacts:'}
        </h2>
        <div className='contact-info'>
          <FaMapMarkerAlt color='#f55302' size={30} className='contact-icon' />
          <a
            href='https://www.google.com/maps/place/%D0%94%D1%80.+%D0%94%D1%80%D0%B0%D0%B3%D0%B8%D1%88%D0%B5+%D0%9C%D0%B8%D1%88%D0%BE%D0%B2%D0%B8%D1%9B%D0%B0+75,+%D0%A7%D0%B0%D1%87%D0%B0%D0%BA+32000/@43.8867021,20.3522969,17z/data=!3m1!4b1!4m5!3m4!1s0x4757726a83884003:0x65f90914f890b98e!8m2!3d43.8866983!4d20.3544856?hl=sr'
            target='_blank'
            rel='noreferrer'
            className='contact-address'
          >
            {srb ? 'Чачак, Драгише Мишовића 89' : 'Čačak, Dragiše Mišovića 89'}
          </a>
        </div>
        <div className='contact-info'>
          <FaEnvelope color='#d4a600' size={30} className='contact-icon' />
          <p className='contact-email'>kodcacak@gmail.com</p>
        </div>
        <div className='contact-info'>
          <FaPhone color='#0b8c3f' size={30} className='contact-icon' />
          <p
            onClick={() => window.open('tel:+38132371518')}
            className='contact-phone'
          >
            032371518
          </p>
        </div>
        <div className='contact-info'>
          <FaMobileAlt color='#dedfe0' size={30} className='contact-icon' />
          <p
            onClick={() => window.open('tel:+38163383639')}
            className='contact-mobile'
          >
            063383639
          </p>
        </div>
        <div className='contact-info'>
          <FaInstagram
            size={30}
            className='contact-icon contact-instagram-icon'
          />
          <a
            href='https://www.instagram.com/arhitektonski_studio_kod/'
            className='contact-instagram'
            target='_blank'
            rel='noreferrer'
          >
            arhitektonski_studio_kod
          </a>
        </div>
        <div className='contact-info'>
          <FaYoutube size={30} className='contact-icon contact-youtube-icon' />
          <a
            href='https://www.youtube.com/channel/UCngfDxB-gjG8kKHd6g9Jpaw'
            className='contact-youtube'
            target='_blank'
            rel='noreferrer'
          >
            {srb ? 'Ковачевић Душан' : 'Kovačević Dušan'}
          </a>
        </div>
        <div className='contact-info'>
          <span className='contact-icon contact-icon-pib'>
            {srb ? 'ПИБ:' : 'PIB:'}
          </span>
          <p className='contact-pib'>113078920</p>
        </div>
        <div className='contact-info'>
          <span className='contact-icon'>{srb ? 'МБ:' : 'MB:'}</span>
          <p className='contact-mb'>66561747</p>
        </div>
      </div>
      <div className='contact-mid-line'></div>
      <div className='contact-images'>
        <div className='contact-img'>
          <img src='/images/kontaktslika1.jpg' alt='arhitektonski studio kod' />
        </div>
        <div className='contact-img'>
          <img src='/images/kontaktslika2.jpg' alt='arhitektonski studio kod' />
        </div>
      </div>
    </div>
  );
};

export default Contact;
