import './_buildingsImages.scss';

const Competitions = () => {
  return (
    <div className='buildings-images'>
      <div className='buildings-images-img'>
        <img src='/images/projects/konkursi/1 - KONKURSI.jpg' alt='konkurs' />
      </div>
      <div className='buildings-images-img'>
        <img src='/images/projects/konkursi/2 - KONKURSI.jpg' alt='konkurs' />
      </div>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/konkursi/3 - KONKURS DUVANSKI KOMBINAT.jpg'
          alt='konkurs'
        />
      </div>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/konkursi/4 - KONKURS SLAVIJA.jpg'
          alt='konkurs'
        />
      </div>
    </div>
  );
};

export default Competitions;
