import './_buildingsImages.scss';

const BusinessAndProductionFacilities = () => {
  return (
    <div className='buildings-images'>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/poslovni/1 - WV SALON AUTOMOBILA.jpg'
          alt='poslovni'
        />
      </div>
      <div className='buildings-images-img'>
        <img src='/images/projects/poslovni/2 - POSLOVNI.jpg' alt='poslovni' />
      </div>
      <div className='buildings-images-img'>
        <img src='/images/projects/poslovni/3 - POSLOVNI.jpg' alt='poslovni' />
      </div>
    </div>
  );
};

export default BusinessAndProductionFacilities;
