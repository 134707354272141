import './_buildingsImages.scss';

const OrtodoxBuildings = () => {
  return (
    <div className='buildings-images'>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/pravoslavni/1 - VERSKI OBJEKTI.jpg'
          alt='pravoslavni'
        />
      </div>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/pravoslavni/2 - VERSKI OBJEKTI.jpg'
          alt='pravoslavni'
        />
      </div>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/pravoslavni/3 - VERSKI OBJEKTI.jpg'
          alt='pravoslavni'
        />
      </div>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/pravoslavni/4 - VERSKI OBJEKTI.jpg'
          alt='pravoslavni'
        />
      </div>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/pravoslavni/5 - VERSKI OBJEKTI.jpg'
          alt='pravoslavni'
        />
      </div>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/pravoslavni/6 - VERSKI OBJEKTI.jpg'
          alt='pravoslavni'
        />
      </div>
      <div className='buildings-images-img'>
        <img
          src='/images/projects/pravoslavni/7 - verski objekat.jpg'
          alt='pravoslavni'
        />
      </div>
    </div>
  );
};

export default OrtodoxBuildings;
