import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './_header.scss';
import { FaBars, FaTimes } from 'react-icons/fa';

const Header = ({ changeToSrb, changeToEng, srb }) => {
  const [menu, setMenu] = useState(false);

  const handleMenu = () => {
    setMenu((prevState) => !prevState);
  };

  const closeMenu = () => {
    if (menu === true) {
      setMenu(false);
    }
  };

  return (
    <header className={menu ? 'header-height' : null}>
      <nav>
        <Link to={{ pathname: '/' }} onClick={closeMenu}>
          <img
            title={srb ? 'Почетна страница' : 'Homepage'}
            className='logo'
            src='/images/logo-big-dark.png'
            alt='logo'
          />
        </Link>
        <ul className={menu ? 'menu-active' : null}>
          <li>
            <NavLink
              onClick={closeMenu}
              className={({ isActive }) =>
                isActive ? 'nav-link nav-link-active' : 'nav-link'
              }
              to={{ pathname: '/biography' }}
            >
              {srb ? 'Биографија' : 'Biography'}
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={closeMenu}
              className={({ isActive }) =>
                isActive ? 'nav-link nav-link-active' : 'nav-link'
              }
              to={{ pathname: '/projects/home' }}
            >
              {srb ? 'Пројекти' : 'Projects'}
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={closeMenu}
              className={({ isActive }) =>
                isActive ? 'nav-link nav-link-active' : 'nav-link'
              }
              to={{ pathname: '/pricing' }}
            >
              {srb ? 'Ценовник' : 'Pricing'}
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={closeMenu}
              className={({ isActive }) =>
                isActive ? 'nav-link nav-link-active' : 'nav-link'
              }
              to={{ pathname: '/contact' }}
            >
              {srb ? 'Контакт' : 'Contact'}
            </NavLink>
          </li>
        </ul>
        <div className='flags'>
          <img
            onClick={changeToSrb}
            title={!srb ? 'Српски језик' : 'Serbian language'}
            src='/images/serbia-flag.png'
            alt='Serbia Flag'
            className='flag'
          />
          <img
            onClick={changeToEng}
            title={!srb ? 'Енглески језик' : 'English language'}
            src='/images/gbr-flag.png'
            alt='GBR Flag'
            className='flag'
          />
        </div>
        {menu ? (
          <FaTimes onClick={handleMenu} className='menu-btn' color='#cf1f1f' />
        ) : (
          <FaBars onClick={handleMenu} className='menu-btn' color='#2c1302' />
        )}
      </nav>
    </header>
  );
};

export default Header;
