import React from 'react';
import './_projectsItem.scss';

const ProjectsItem = ({ imgSrc, title }) => {
  return (
    <div className='projects-item'>
      <div
        className='projects-img'
        style={{ background: `url(${imgSrc}) no-repeat center center/cover` }}
      ></div>
      <p className='projects-title'>{title}</p>
    </div>
  );
};

export default ProjectsItem;
